<template>
  <div class="content">
    <nav-bar header-title="团课课量明细" :show-back="true" :header-back="back" ref="head" :placeholder="false" />
    <div :style="{ paddingTop: stickyHeight + 'px' }">
      <van-sticky :offset-top="stickyHeight">
        <div class="tab">
          <span>团课课量<span class="f32">&nbsp;{{ lessonNum }}&nbsp;</span>节</span>
          <span @click="mShow = true">{{ mText }}<van-icon name="play" class="icon" /></span>
        </div>
      </van-sticky>
      <div v-for="i in list" class="item">
        <!-- @click="goDetail(i)" -->
        <p class="line1">
          <span class="f26 mr20">{{ i.subject }}</span>
          <span class="pub_onefull">
            <span class="first" v-if="i.newUserFlag">首次团课</span>
          </span>
          <span class="f32 fw6">{{ i.newUserFlag ? '+5*' : '' }}1节</span>
        </p>
        <p class="time">{{ i.lessonTime }}</p>
      </div>
      <div v-if="list.length == 0" class="empty">
        <img :src="baseImgUrl + 'empty.png'" alt="">
        <p>暂无团课上课数据</p>
      </div>
    </div>
    <van-popup v-model="mShow" safe-area-inset-bottom position="bottom" class="pop-radius">
      <van-picker title="月份选择" show-toolbar :columns="mList" @confirm="monthConfirm" @cancel="mShow = false"
        :default-index='defaultIndex' />

    </van-popup>
  </div>
</template>

<script>
import navBar from '@/components/nav-bar/nav-bar'
import { getLastMonth, getMonthStartAndEnd, getMonthRange } from '@/lib/utils';
import userMixin from '@/mixin/userMixin';

export default {
  components: {
    navBar
  },
  mixins: [userMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/teamWarband-202308/',
      stickyHeight: 0,
      mShow: false,
      mList: [],
      mText: '',
      mValue: '',
      defaultIndex: 0,
      lessonNum: 0,
      list: [],
      cityId2: ''
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
    }, 100)

  },
  async created() {
    await this.$getAllInfo(['userId'])
    this.mList = getLastMonth(getMonthRange(new Date('2024-09-01'), new Date()) + 1)
    this.mValue = this.$route.query.mValue
    this.cityId2 = this.$route.query.cityId
    this.mList.forEach((item, index) => {
      if (item.value === this.mValue) {
        this.mText = item.text
        this.defaultIndex = index
      }
    })
    this.getList()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    monthConfirm(m) {
      this.mText = m.text
      this.mValue = m.value
      this.mShow = false
      this.getList()
    },
    getList() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      let startTime = this.mValue + '-01 00:00:00'
      let endTime = getMonthStartAndEnd(this.mValue + '-01').endDate + ' 23:59:59'
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/lessonNumDetail', {
        userId: this.userId,
        cityId: this.cityId2,
        startTime, endTime,
        thisMonthFlag: this.mValue === this.mList[0].value ? 1 : 0
      }).then((res) => {
        this.lessonNum = res.data.lessonNum
        this.list = res.data.detailList
      })
    },
    // goDetail(i){
    //   gotoCourseDetail(i.appointId)
    // }
  }
}
</script>

<style lang="less" scoped>
.content {
  background: #F5F5F5;
  min-height: 100vh;
  padding-bottom: 30px;

  .tab {
    background-color: #F5F5F5;
    padding: 38px;
    padding-left: 66px;
    font-size: 28px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .icon {
      transform: rotate(90deg);
      margin-top: -1px;
    }
  }

  .empty {
    text-align: center;
    padding-top: 250px;

    img {
      width: 320px;
      height: 320px;
    }

    p {
      color: #6C727A;
      font-size: 28px;
      padding-top: 10px;
    }
  }

  .item {
    width: 686px;
    height: 154px;
    margin: 0 auto 24px;
    padding: 36px 32px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 20px;
    font-size: 22px;

    .line1 {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .first {
        padding: 6px 9px;
        background-color: #F24C2A;
        color: #fff;
        border-radius: 4px;
      }
    }

    .time {
      color: #6C727A;
    }
  }
}
</style>